export const WORDS = [
    '😱😖😝😴',
    '😖😎😙😎',
    '😙🤔😎😎',
    '😖🤔🥰🤔',
    '😖😭🤔😖',
    '🤗😝🥰😭',
    '😍😙😵😖',
    '🤔😴😵😺',
    '🥰😺😭😺',
    '😵🥰😖😱',
    '😙😺😖😎',
    '😙🤗😺😖',
    '😉😝😴😭',
    '😺😉😱😙',
    '😺😵😉😴',
    '😺😴🥰😍',
    '😖😍🤔😭',
    '😱😺😵😱',
    '😙😙😖😭',
    '😎😙😖😎',
    '😙😎😭🤗',
    '😝😉🥰😱',
    '😵🤔😴😭',
    '😭😺🤗🥰',
    '😺😱😴😍',
    '😱😭😉🤔',
    '😎😺😱😉',
    '🤗🤔😱😍',
    '😝😙🤗😎',
    '😵😖😉😴',
    '😵🤔😴🥰',
    '😱😺🤔😵',
    '🤗😎😺😱',
    '😴😖🥰😝',
    '😖😴😺😉',
    '😍😎😵😉',
    '😺🥰😉😍',
    '😴😱🥰😖',
    '😎😭🤗🤔',
    '😖🥰😍😭',
    '😴😉🤗😵',
    '😴😵😺😭',
    '😺😉😙😎',
    '😺😭😍😱',
    '😺😺😺😎',
    '😍😍😴😱',
    '😱😝😺😙',
    '😺😖🤔😎',
    '🥰😉😭😭',
    '😝😎😭😺',
    '😖😵😖😴',
    '😭🤗😴😺',
    '🤔😭😎😭',
    '😭😎😙😍',
    '😉🤔😉😖',
    '😺🥰🤗😎',
    '🥰😵😖😍',
    '😺😖😵😵',
    '😝😱😱😎',
    '😍😭😺😍',
    '🤔😖😉😱',
    '😉😉😉😴',
    '🤗😝😉😉',
    '😝😝😍😵',
    '😙😱😱🤗',
    '😎😙😉😺',
    '😙😖🤔😭',
    '😱😍🤗😭',
    '😙🥰🥰😭',
    '😺😖😺😵',
    '😝😉😵😱',
    '😝😍😍😵',
    '🤗🤔😙😙',
    '😙🥰😱😭',
    '😉😙😙😵',
    '😭😭😺😉',
    '🤗😵😖😍',
    '😵😙😵😍',
    '🤔😙😝🥰',
    '😴🤔😖😴',
    '😺😵🤔😎',
    '😴😱🤗😖',
    '😵😭🤔😱',
    '😺😵😍😍',
    '😝😺😍😖',
    '😙😴🥰😴',
    '😵😝😍😍',
    '😺😙😴🤗',
    '🤔🤔🤗😍',
    '😙😺🥰😱',
    '😭🥰😙🤗',
    '🤗😍🤔😉',
    '😝😎🤔🤗',
    '😉🤗😱😵',
    '😺😙🤔😭',
    '😍😖🤔😝',
    '😙🤔😵😭',
    '😺😭😭😴',
    '😴🥰🥰🤗',
    '😺😵😍😍',
    '😉😎😺😉',
    '😱😙😖😱',
    '🤗😺🥰😉',
    '🤗😉😱😉',
    '😺🥰🤔😭',
    '😖😉😺😙',
    '🤔😎😍😝',
    '😝😙😭😎',
    '😍😺😭😉',
    '😭😝🥰🥰',
    '🥰😙😱😺',
    '😍😴🥰😉',
    '😍😴😭😵',
    '😺🤔😉😵',
    '😭😱😝😴',
    '🤔😭😍😺',
    '😝😖🤔😖',
    '🥰😱🥰😝',
    '😱😝🤔😉',
    '😉😎🤔😺',
    '😙😙🤗🥰',
    '😴😍😙😴',
    '😺😖😱😉',
    '😉🤔😺😵',
    '😵😎😖😖',
    '😱😖🤔😙',
    '🤔🤔😎😎',
    '😺🤗😺😭',
    '😺🤗😝😎',
    '😭🥰🤗😎',
    '😝😙😝😺',
    '😎😺😺😝',
    '😖🤔🤔😴',
    '🤗😺😍😺',
    '😖😎😺😙',
    '🤔😙😝😎',
    '🤗🥰😭😝',
    '😵😺😵🥰',
    '😍😝😝😉',
    '🤗😉😉😖',
    '😭🤔😭😝',
    '😍😙😉😝',
    '😴😴🤗🤗',
    '😎😺😴😭',
    '😭😴🤔😱',
    '😖😎🤗😍',
    '😭😝🤗😎',
    '🥰😵😵😺',
    '🥰😺😝😙',
    '🤗😱😙😖',
    '😍😵😖😙',
    '😺😭😍😝',
    '😱🤔😉😍',
    '😎😭😭😴',
    '😱😵🤔😖',
    '😙🤗😭😵',
    '😝🥰😵😎',
    '😭😱😭🤔',
    '😱😺😝🥰',
    '🤔😎😴🤗',
    '🥰😍😉😉',
    '😖😺😴🥰',
    '😵😎😴😴',
    '🤗😴😵😎',
    '😖😵😭😵',
    '🤔😺🥰😵',
    '😖😱😴🤔',
    '😴😱😺😉',
    '😴😎🥰😭',
    '😱😝😍😵',
    '😙🤗🤗😱',
    '😎😖😭🤔',
    '😴😎😖😱',
    '😴😱😖😝',
    '😱😝😵😵',
    '😱🤗😵😙',
    '😝😉😍😖',
    '😭😎😍🤔',
    '🥰🤔😉😱',
    '😭😵😖😖',
    '😎😝😱😴',
    '😉🥰😉😝',
    '😱😍🥰😭',
    '😺🤔🤔😝',
    '😖😎😍😱',
    '😱😖🤔🤔',
    '😭😉🤗😱',
    '😍🤗😉😙',
    '😉😵😺😱',
    '😖🥰🤔😉',
    '😖😖😭🥰',
    '😝😍😵😴',
    '🤔😖😺😉',
    '😉🤗😴😎',
    '😉😖🥰😉',
    '😖😎🤗🤗',
    '😴😉🥰😖',
    '😵😎🤗🤗',
    '😝😱😍😴',
    '😺😉😝🤗',
    '😖🤔😖😺',
    '😴😝😙😱',
    '😱😱🤔😱',
    '😵🤗😉😙',
    '😙😺😍😖',
    '😙😉😉😱',
    '😝😝😱🥰',
    '🥰🤗😙😉',
    '😵🥰😙😖',
    '😎😖😺🤗',
    '😙😭😎😴',
    '😱😺😭🥰',
    '😱😝😺😙',
    '😖😎🤔😖',
    '😭🥰😍🤗',
    '😵😝😴😙',
    '🤔😙😝😴',
    '😎🥰🤔😎',
    '😉😭😎😖',
    '😺😴🤗😉',
    '🥰🥰😵🥰',
    '🥰🤗😵😉',
    '😭🥰😖😙',
    '😺😺😉🤗',
    '🤔🤔😴😱',
    '😵😖😎😴',
    '🤔😙😺😍',
    '😙🤗😭😎',
    '😭😖😵😝',
    '🤔😝😉😴',
    '😙🤔😵😵',
    '😱😴😖😴',
    '🥰😉🤔😵',
    '😱😭😉😝',
    '😉🥰😭😍',
    '😭😵😝😭',
    '😎😙🤔😝',
    '😍😭🤔😉',
    '🤔😖😍😖',
    '😺😝😎😙',
    '😎😙😍😵',
    '😴😴🤗🤗',
    '😵😵😎😉',
    '🤔😎😙😉',
    '😴😙🤗😖',
    '😵🤔😭😺',
    '😭😙🥰😍',
    '😵🤗😎😙',
    '😭😖😖😙',
    '😺😉😴🤔',
    '😙🤔😍😖',
    '😍😉😺😍',
    '😉😉😱🤔',
    '😎😖😱😱',
    '😭🥰🤗😭',
    '😵🥰😎🥰',
    '🥰😖😙🤔',
    '🤗😴😍🤗',
    '😭😱😙🤔',
    '🤔😺😱😉',
    '🤔😴😎😉',
    '😭😍😭😭',
    '🤔😙😍🤔',
    '😺🤔😺😍',
    '😵😍😱🤗',
    '😵🥰😵😍',
    '😭😭😍😭',
    '🤗😺😝😱',
    '😴😉😱😙',
    '😝😱😎😙',
    '😉😵😭😉',
    '🤔😺😱🥰',
    '😙😴😝😴',
    '😍😉😉😙',
    '😝🤔😵😱',
    '😍🥰🥰🤔',
    '😱😎🤗😖',
    '😉😭😖😺',
    '🤗😱😱😺',
    '😙😙😺😖',
    '😴😉😎😴',
    '😎😎😉😵',
    '😺🤗🤗😙',
    '😵😱😎🤗',
    '😵🤔😎😺',
    '😉😖😱🤗',
    '😴🥰😭😺',
    '🤗🤗😴🤔',
    '😭🥰😱🥰',
    '😎🤗😴🤔',
    '😎🥰😺🥰',
    '😎🤗🤔😝',
    '🤔😴😍🤗',
    '😝😍😉😎',
    '😺😴😉😝',
    '😖😵😍🤔',
    '😍😱😭😝',
    '😴😎😴😵',
    '😖🤔😴🤔',
    '😝😴😭😭',
    '😵🤗🥰😎',
    '😵😺🥰😝',
    '😉😝🤗🥰',
    '😙😴😎🤔',
    '😭🤗😍😎',
    '😍🥰😉😙',
    '🤗😉😙😍',
    '😵🤔😝😎',
    '😱😎🥰😭',
    '🤔😵😎😙',
    '😉😎😉😎',
    '😍🤗😍🤗',
    '🥰😙😱😴',
    '😱😉😎😍',
    '🤗😱😍😖',
    '🤗😖😝🤔',
    '🤗🥰🤔🤗',
    '😙😺🤔😉',
    '😴😭😉🥰',
    '😴😉😉😙',
    '🤔😍😱😙',
    '😺😉😍🤗',
    '😱😴🥰😴',
    '😵😉🥰😎',
    '😭🤗😖😉',
    '🥰😖😝😱',
    '😴🥰🤔😵',
    '😴😝🥰😝',
    '😱😺😉😖',
    '🥰😺😭😝',
    '🥰😍😭😺',
    '😴😍😍😎',
    '😴😎😭🤗',
    '😝😍😉😉',
    '😱😉😖😎',
    '😙😎😱😱',
    '😵😖😎🥰',
    '😱😙😝😝',
    '😎😖🤔😝',
    '😎😴😍🥰',
    '😎🥰😭😉',
    '😍😙😍😵',
    '😖😉😙😎',
    '😱😝😉😵',
    '😉🤔😴😱',
    '😖🥰😍😺',
    '😭😎😵🥰',
    '🤔😵😖😖',
    '😉😵😵🥰',
    '😺😍🥰😴',
    '😉🤔😱😺',
    '😉🤔😭🤗',
    '😺😵😝😱',
    '😱🤗🤗🤔',
    '😝😍😱🤔',
    '😭😉😱🤗',
    '😭😺😍😙',
    '🤗😺😵😖',
    '😱🤔😉😺',
    '🤗😎😝😎',
    '😵😝😎🤗',
    '😙😙🤗😉',
    '😭🥰😖🤔',
    '😎😱😉😙',
    '😍😱😴😉',
    '🥰🤗😙😙',
    '😭😙🤔😎',
    '😱😍😴😺',
    '😵😴😎🥰',
    '😺😉😴😴',
    '😵🤔😖😺',
    '😝😵😺🤔',
    '😉🤔😴😖',
    '😉😝😎😵',
    '😎😖😉😙',
    '😴🥰😉😎',
    '😍😵😍😝',
    '😵😍😱😺',
    '😝😉😴😴',
    '🥰😵😙😖',
    '😎😖😝😱',
    '😭🤗😴😝',
    '😱🤔🤔😝',
    '😖😭😴😎',
    '😝😝🤔🤔',
    '😎😵😭😝',
    '😴😉😴😺',
    '😙😱😴😺',
    '😍😉😱😉',
    '🥰😭😵😉',
    '😙😍😭😴',
    '😵😺😺😭',
    '😱🥰😖🥰',
    '😴😱😴😺',
    '😍🥰😙😎',
    '😴😙😎😎',
    '😎🤗🥰😉',
    '😉😎🤗🤗',
    '🥰😎🤔😺',
    '😝😴😺😍',
]
