export const keys = [
    ['🥰', '😭', '😱', '🤔', '😍'],
    ['😎', '😵', '😴', '😖', '🤗'],
    ['😉', '😙', '😝', '😺'],
];

export const flatKeys = keys.reduce((a,i) => a.concat(i), []);

// 🥰 😭 😱 🤔 😍
// 😎 😵 😴 😖 🤗
// 😉 😙 😝 😺